<template>
	<div class="orderItem">
		<div class="tit">
			<div class="time">
				{{i18n('285')}}：{{data.order.create_time*1000 | formatTime('YMDHMS')}}
			</div>
			<div class="orderNumber">
				{{i18n('286')}}：{{data.order.order_number}}
			</div>
			<div class="djs" v-if="status==1">
				{{time}}{{i18n('287')}}
			</div>
		</div>
		<div class="inn">
			<div class="info">
				<div class="left leftOne" v-if="data.commodity.length==1" @click="tz">
					<div class="zt">
						<img :src="data.commodity[0].image" alt="">
					</div>
					<div class="name">
						{{lang=='zh'?data.commodity[0].commodity_zh_name:lang=='en'?data.commodity[0].commodity_en_name:data.commodity[0].commodity_vi_name}}
					</div>
				</div>
				<div class="left leftTwo" v-if="data.commodity.length>1">
					<div class="zt" v-for="(item,index) in data.commodity" :key="index" @click="tz">
						<img :src="item.image" alt="">
					</div>
				</div>
				<div class="right">
					<div class="sm">
						<div class="">
							{{data.order.delivery_modeint==2?i18n('85'):i18n('100')}}
						</div>
						<div class="" v-if="data.commodity.length>1">
							{{i18n('288')}}{{data.commodity.length}}{{i18n('289')}}
						</div>
					</div>
					<div class="but butAc" v-if="status==1">{{i18n('186')}}</div>
					<div class="but" v-if="status==2">{{i18n('187')}}</div>
					<div class="but" v-if="status==3">{{i18n('188')}}</div>
					<div class="but" v-if="status==4">{{i18n('189')}}</div>
					<div class="but" v-if="status==5">{{i18n('190')}}</div>
					<div class="but" v-if="status==6">{{i18n('191')}}</div>
					<div class="but" v-if="status==7">{{i18n('192')}}</div>
				</div>
			</div>
			<div class="hj">
				<div class="">
					<div class="sm">
						{{i18n('115')}}
					</div>
					<div class="je">
						₱{{data.order.total_price}}
					</div>
				</div>
			</div>
			<div class="ck">
				<div class="">
					<div class="xq" @click="$router.push('/orderDetails?order_id='+data.order.id)">
						{{i18n('96')}}
					</div>
					<div class="cz" v-if="status==1" @click="$router.push('/pay?set_id='+data.set.id+'&pay_price='+data.order.total_price)">
						{{i18n('290')}}
					</div>
					<div class="cz" v-if="status==4" @click="sh">
						{{i18n('184')}}
					</div>
					<!-- <div class="cz" v-if="status!=4 && status!=1">
						再来一单
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],
		name: "orderItem",
		data() {
			return {
				time: 0
			}
		},
		methods: {
			sh(){
				this.$message.success(this.i18n('185'))
			},
			tz() {
				// if(this.status==5 || this.status==7){
				// 	//去售后
				// 	this.navigateTo('/pages/afterSales/details/index?order_id='+this.data.order.id)
				// }else{
				// 	//去订单详情
				// 	this.navigateTo('/pages/order/details/index?pack_id='+this.data.order.id)
				// }
			},
		},
		computed: {
			status() {
				let status = 0
				if (this.data) {
					let orderStatus = this.data.order.status
					if (orderStatus == 1) {
						status = 1 //待付款
					}
					if (orderStatus == 2 || orderStatus == 3 || orderStatus == 13 || orderStatus == 14) {
						status = 2 //待发货
					}
					if (orderStatus == 4 || orderStatus == 5) {
						status = 3 //待收货
					}
					if (orderStatus == 6 || orderStatus == 7) {
						status = 4 //已完成 可申请售后
					}
					if (orderStatus == 8 || orderStatus == 11 || orderStatus == 12) {
						status = 5 //售后中
					}
					if (orderStatus == 9) {
						status = 6 //订单取消
					}
					if (orderStatus == 10) {
						status = 7 //售后完成 不能再申请售后了
					}
				}
				return status
			},
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		created() {

		},
		mounted() {
			if (this.status == 1) {
				let xdtime = this.data.order.create_time + 1800
				let newDate = (new Date()).valueOf() / 1000;
				let time = xdtime - newDate
				let _this=this;
				this.Interval = setInterval(() => {
					time--
					if (time > 0) {
						this.time = parseInt(time / 60) + _this.i18n('291') + parseInt(time % 60) + _this.i18n('292')
					} else {
						this.time = _this.i18n('94')
						clearInterval(this.Interval)
					}
				}, 1000)
			}
		}
	};
</script>

<style scoped>
	.ck{
		flex: 1;
		padding: 24px 0;
		height: 72px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.ck .xq{
		padding-bottom: 10px;
	}
	.ck .xq,.ck .cz{
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #56AB7B;
		text-align: right;
		font-weight: 500;
		cursor: pointer;
	}
	.hj {
		width: 154px;
		border-right: 1px solid #E8E8E8;
		padding: 24px 0;
		height: 72px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.hj .sm {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		line-height: 14px;
		font-weight: 400;
		margin-bottom: 12px;
		text-align: center;
	}

	.hj .je {
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #F23725;
		text-align: center;
		line-height: 28px;
		font-weight: 500;
	}

	.hj .je span {
		font-size: 14px;
	}

	.inn {
		display: flex;
		align-items: center;
		padding-left: 24px;
	}

	.inn .info {
		width: 700px;
		display: flex;
		align-items: center;
		border-right: 1px solid #E8E8E8;
		padding: 24px 0;
	}

	.inn .info .left {
		width: 420px;
		display: flex;
		align-items: center;
		overflow: hidden;
	}

	.inn .info .left .zt img {
		margin-right: 12px;
		width: 72px;
		height: 72px;
		border-radius: 3.43px;
		display: block;
	}

	.inn .info .left .name {
		flex: 1;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
	}

	.inn .info .right {
		display: flex;
		align-items: center;
		justify-content: right;
		padding-right: 20px;
	}

	.inn .info .right .sm {
		text-align: center;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		line-height: 18px;
		font-weight: 400;
		width: 150px;
	}

	.inn .info .right .but {
		height: 32px;
		border-radius: 22.5px;
		padding: 0 20px;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #4E5969;
		text-align: center;
		font-weight: 500;
		background: #F2F3F5;
		line-height: 32px;
		cursor: pointer;
	}

	.inn .info .right .butAc {
		border: 1px solid rgba(86, 171, 123, 1);
		color: #56AB7B;
	}

	.tit {
		height: 48px;
		background: #FFFFFF;
		border-bottom: 1px solid #56AB7B;
		display: flex;
		align-items: center;
		padding: 0 24px;
	}

	.tit div {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
	}

	.tit div.time {
		font-weight: 500;
		width: 410px;
	}

	.tit div.orderNumber {
		width: 300px;
	}

	.tit div.djs {
		flex: 1;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #56AB7B;
		text-align: right;
		line-height: 19px;
		font-weight: 500;
	}

	.orderItem {
		width: 1032px;
		background: #FFFFFF;
		border-radius: 12px;
		overflow: hidden;
	}
</style>
