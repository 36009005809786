<template>
	<div class="myOrder">
		<div class="title">
			<div class="txt">
				{{i18n('33')}}
			</div>
			<div class="gang">
				/
			</div>
			<div class="tab">
				<div :class="params.status==0?'item itemAc':'item'" @click="statusChange(0)">
					{{i18n('293')}}
				</div>
				<div :class="params.status==1?'item itemAc':'item'" @click="statusChange(1)">
					{{i18n('186')}}
				</div>
				<div :class="params.status==2?'item itemAc':'item'" @click="statusChange(2)">
					{{i18n('187')}}
				</div>
				<div :class="params.status==3?'item itemAc':'item'" @click="statusChange(3)">
					{{i18n('188')}}
				</div>
				<div :class="params.status==4?'item itemAc':'item'" @click="statusChange(4)">
					{{i18n('215')}}
				</div>
			</div>
		</div>
		<div class="list">
			<div class="noData" v-if="noData">
				<div class="">
					<img src="@/assets/noData2.png" alt="">
					<div class="text">
						{{i18n('294')}}
					</div>
					<div class="gw">
						{{i18n('295')}}
					</div>
				</div>
			</div>
			<div class="item" v-for="(item,index) in listData" :key="index">
				<orderItem :data='item'></orderItem>
			</div>
			<div class="pagination" v-if="total!=0">
				<el-pagination layout="prev, pager, next" :total="total" :page-size="params.page_size"
					:current-page='params.page_num' @current-change="currentChange">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		orderList
	} from "@/api/order.js"

	import orderItem from './components/orderItem.vue'

	export default {
		name: "myOrder",
		components: {
			orderItem
		},
		data() {
			return {
				params: {
					status: 0,
					page_num: 1,
					page_size: 10
				},
				total: 0,
				noData: false,
				listData: [],
				type: 1 //1成功    2失败
			}
		},
		methods: {
			statusChange(type) {
				this.params.status = Number(type)
				this.params.page_num = 1
				this.noData = false
				this.orderList()
			},
			currentChange(page_num) {
				this.params.page_num = page_num
				this.orderList()
			},
			async orderList() {
				let {
					data,
					total
				} = await orderList(this.params)
				this.listData = data
				this.total = total

				if (total==0) {
					this.noData = true
				} else {
					this.noData = false
				}
			},
		},
		computed: {

		},
		created() {
			this.$store.commit('user/setClassifyShow', false)
			// this.params.status=Number(status)
			this.orderList()
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.gw{
		width: 310px;
		height: 44px;
		background: #56AB7B;
		border-radius: 22.5px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		line-height: 44px;
		margin: 0 auto;
		margin-top: 87px;
	}
	.noData {
		width: 1032px;
		height: 458px;
		background: #FFFFFF;
		border-radius: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.noData img {
		width: 100px;
		height: 100px;
		display: block;
		margin: 0 auto;
	}
	
	.noData .text {
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #86909C;
		line-height: 20px;
		font-weight: 400;
		text-align: center;
		margin-top: 10px;
	}
	.list .item {
		margin-bottom: 16px;
	}

	.title {
		height: 69px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #1D2129;
		line-height: 69px;
		font-weight: 500;
		display: flex;
		align-items: center;
	}

	.title .gang {
		font-size: 12px;
		color: #ccc;
		line-height: 69px;
		padding: 0 8px;
	}

	.title .tab {
		display: flex;
		align-items: center;
	}

	.title .tab .item {
		margin-right: 16px;
		padding: 0 12px;
		height: 24px;
		background: rgba(86, 171, 123, 0.10);
		border-radius: 4px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		line-height: 24px;
		font-weight: 400;
		cursor: pointer;
	}

	.title .tab .itemAc {
		background: #56AB7B;
		color: #fff;
	}

	.myOrder {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.list {
		flex: 1;
		overflow-y: scroll;
	}
	>>>.el-pagination .btn-next {
		background: none;
	}
	
	>>>.el-pagination button:disabled {
		background: none;
	}
	
	>>>.el-pagination .btn-prev {
		background: none;
	}
	
	>>>.el-pager li.active {
		color: #56AB7B;
	}
	
	>>>.el-pager li {
		background: none;
	}
	
	.pagination {
		width: 100%;
		height: 36px;
		padding-bottom: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
